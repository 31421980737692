import React, { Component } from 'react';
import marked from 'marked';
import TileItemMixin from 'components/BaseEntities/TileItemMixin';


// Container

export default class ContextTile extends Component {

  render() {
    const { items, actions, loading, descriptions, meta, data_mart } = this.props;
    let entities_class = "entities ex-tiles";
    entities_class = loading ? entities_class + " ex-state-loading" : entities_class;

    return (
      <ul className={entities_class}>
        {items.map(
          (child, i) =>
          <ContextTileItem key={i} data={child} actions={actions} descriptions={descriptions} position={i} meta={meta} context={data_mart.context}/>
        )}
      </ul>
    );
  }
}

const PROFIT_BASE_PATTERN = "external_url";

// Element

class ContextTileItem extends TileItemMixin(Component) {

  getDescriptionBaloon(characteristics){
    if (characteristics.length) {
      return (
        <div className="ex-description-wrapper">
          <div className="ex-baloon">
            <div className="ex-arrow"></div>
            <ul className="ex-attrs">
              {characteristics.map(
                (child, i) =>
                  <li data-path={child.path} key={i}
                    data-view-class={child.view_class.join(" ")}>
                    <strong>{child.name}:&nbsp;</strong>
                    {child.values.join("; ")}
                  </li>
              )}
            </ul>
          </div>
        </div>
      );
    }
  }

  // Кэширование метода для каталога Profitbase
  redirectUrl(data, marks, url){
    if (!data._url)
      data._url = this.getRedirectUrl(marks, url);
    return data._url
  }

  getRedirectUrl(marks, url){
    for (const sm of marks) {
      for (const cl of sm.view_class) {
        if (cl.startsWith(PROFIT_BASE_PATTERN)) {
          url = sm.values[0];
          break;
        }
      }
    }
    return url;
  }

  render() {
    const { data, position, meta, descriptions } = this.props,
          index = position + meta.offset,
          groupSize = data.extra.group_size || 0;

    let url = (data.extra && data.extra.url ? data.extra.url : data.entity_url) +
              (this.props.context && this.props.context !== PROFIT_BASE_PATTERN ? ('?ctx=' + this.props.context) : '');

    let groupDigit = "";
    if (groupSize) {
      groupDigit = (
        <span className="ex-digit">{groupSize}</span>
      );
    }

    let liClass = "ex-catalog-item";
    if (descriptions.opened[data.id]) {
      liClass += " ex-state-description";
    }

    let characteristics = data.short_characteristics || [],
        marks = data.short_marks || [];

    let descriptions_data = groupSize ? descriptions.groups : descriptions;
    // let related_data_marts = [];
    if (descriptions_data[data.id]) {
        characteristics = descriptions_data[data.id].characteristics || [];
        marks = descriptions_data[data.id].marks || [];
        // related_data_marts = descriptions[data.id].marks || [];
    }

    if (this.props.context === PROFIT_BASE_PATTERN)
      url = this.redirectUrl(data, marks, url);

    const title = groupSize && !meta.alike ? data.extra.group_name : data.entity_name;

    let pattern = /(?:href=)["\'](.*?)["\']/;
    let media = data.media.replace(pattern, "href='" + url + "'");

    const itemContent = (
      <div className="ex-wrap-action">
        <div className="ex-media"
             dangerouslySetInnerHTML={{__html: marked(media, {sanitize: false})}}>
        </div>

        <ul className="ex-ribbons">
          {marks.map(
            (child, i) =>
              <li className="ex-wrap-ribbon"
                  key={i}
                  data-name={child.name}
                  data-path={child.path}
                  data-view-class={child.view_class.join(" ")}>
                <div className="ex-ribbon">{child.values.join(", ")}</div>
              </li>
          )}
        </ul>

        <div className="ex-wrap-title">
          <h4 className="ex-title">
            <a href={url} title={title}>{title}</a>
          </h4>
        </div>
      </div>
    );

    const descriptionBaloon = this.getDescriptionBaloon(characteristics) || "",
          itemBlock = this.getItemBlock(descriptionBaloon, itemContent, groupDigit, groupSize);

    return (
      <li className={liClass}
          data-horizontal-position={this.state.h_pos}
          data-vertical-position="center"
          data-index={index}
          onMouseOver={e => { ::this.handleMouseOver(e); } }
          onMouseOut={e => { ::this.handleMouseOut(e); } }>
          {itemBlock}
      </li>
    );
  }
}