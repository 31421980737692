import React, { Component } from 'react';

import BaseEntities from 'components/BaseEntities';
import NewsShortList from 'components/Entities/NewsShortList';
import ContextTile from 'components/Entities/ContextTile';


export default class Entities extends Component {

    static getTemplates() {
        return Object.assign(BaseEntities.getTemplates(), {
            "news_short_list": NewsShortList,
            "context_tile": ContextTile,
        });
    }

    render() {
        return <BaseEntities {...this.props} getTemplates={Entities.getTemplates}/>
    }
}

