import React, { Component } from 'react';
import  marked from 'marked';


export default class NewsShortList extends Component {
  render() {
    const { items, actions, loading, descriptions } = this.props;
    let entities_class = "entities list-items";
    entities_class = loading ? entities_class + " ex-state-loading" : entities_class;

    return (
      <div className={"row " + entities_class}>
        {items.map(
          (child, i) =>
          <NewsShortListItem key={i} data={child} actions={actions} descriptions={descriptions}/>
        )}
      </div>
    );
  }
}

class NewsShortListItem extends Component {
  render() {
    const data = this.props.data,
          url = data.extra && data.extra.url ? data.extra.url : data.entity_url,
          marks = data.short_marks || [];

    let created_at = "";
    if (data.extra && data.extra.created_at) {
          created_at = new Date(data.extra.created_at);
          let options = { year: 'numeric', month: 'short', day: 'numeric' };
          created_at = created_at.toLocaleDateString('ru-RU', options);
    }
    return (
      <div className="col-md-4 news-short-list-item">
          <div className="ex-catalog-item">
              <a href={url}>
                <div dangerouslySetInnerHTML={{__html: marked(data.media, {sanitize: false})}}/>
              </a>
          </div>
            <div className="news-title">
                <h4>
                      <a href={url} title={data.entity_name}>{data.entity_name}</a>&nbsp;
                </h4>
                <span className="news-article">
                  <i className="fa fa-calendar"></i>&nbsp;
                  {created_at}
                </span>
            </div>
      </div>
    );
  }
}
