require('es6-promise').polyfill();
require('isomorphic-fetch');

import React from 'react';
import { render } from 'react-dom';
import configureStore from 'store/configureStore';
import Root from 'components/Root';
import Singleton from 'utils/singleton';

const globalStore = new Singleton();
const data_marts = document.getElementsByClassName('ex-data-mart');

for (let i = data_marts.length - 1; i >= 0; i--) {
  const data_mart = data_marts[i],
        store = configureStore(),
        dom_attrs = data_mart.attributes,
        entry_points = JSON.parse(dom_attrs.getNamedItem('data-entry-points').value),
        entry_point_id = dom_attrs.getNamedItem('data-selected-entry-point-id').value,
        entry_point = entry_points[entry_point_id];

  let alias = entry_point['store-alias'];

    if ( !alias ) {
        alias = `data_mart_${entry_point_id}`;
    };

globalStore[alias] = store;

  render(
      <Root store={store} dom_attrs={dom_attrs} />,
      data_mart
  );
}

// setTimeout(function(){
//     let store = new Singleton();
//     console.log("+++ Test +++", store['data_mart_2'].getState());
// }, 4000);
